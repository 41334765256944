
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import { Editor } from '@tinymce/tinymce-react';

class Html extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="input-wrap">
                <label>{this.props.translate(this.props.label)}</label>
                <Editor
                    apiKey="qx3j1e5sninc73lra4skxtz14igr8jyfmogn42ytw5csgel5"
                    init={{ plugins: 'link code table', height: 500 }}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : typeof this.props.value == 'string' ? this.props.value : '' : this.props.value}
                    onEditorChange={(val) => {

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value || typeof value === 'string') {
                                value = {};
                            }

                            value[this.props.lang] = val;
    
                            this.props.onChange(value);
                        } else {
    
                            this.props.onChange(val);
                        }
                        this.forceUpdate();
    
    
                    }}
                />
            </div>
        );
    }
}

export default Html;