
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col

} from 'reactstrap';


class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citySearch: undefined,
            streetSearch: undefined,
            results: [],
            cities: []
        };
    }

    componentDidMount() {

    }

    searchCity = () => {
        fetch('http://localhost:61005/locations/city/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search: this.state.citySearch })
        }).then((res) => res.json()).then((result) => {
            this.setState({
                cities: result,
                cityDropdownOpen: true
            })
        })
    }

    render() {
        return (
            <Row>
                <Col lg="6">
                    <div className="input-wrap search-select address-select">
                        {this.props.label ? <label>Ulica</label> : null}

                        <input type="text" onChange={(e) => {
                            this.setState({ streetSearch: e.target.value });
                            this.props.onChange({
                                Street: e.target.value
                            });

                        }} value={this.state.streetSearch !== undefined ? this.state.streetSearch : this.props.value && this.props.value.Street ? this.props.value.Street : this.props.placeholder}></input>
                    </div>

                </Col>

                <Col lg="6">
                    <div className="input-wrap search-select address-select">
                        {this.props.label ? <label>Grad / PTT</label> : null}

                        <Dropdown className={this.props.error ? 'select-field required' : 'select-field'} isOpen={this.state.cityDropdownOpen} toggle={() => { this.setState({ cityDropdownOpen: !this.state.cityDropdownOpen }) }}>
                            <DropdownToggle nav caret>
                                <input type="text" onChange={(e) => this.setState({ citySearch: e.target.value }, this.searchCity)} value={this.state.citySearch !== undefined ? this.state.citySearch : this.props.value && this.props.value.placeId ? this.props.value.City + ' ' + this.props.value.placeId : this.props.placeholder}></input>
                            </DropdownToggle>
                            {this.state.cities && this.state.cities.length ?
                                <DropdownMenu className="dropdown-animation">
                                    {
                                        this.state.cities.map((children, idx) => {
                                            return (
                                                <DropdownItem onClick={() => {
                                                    this.setState({
                                                        citySearch: children.naziv + ' ' + children.ptt,
                                                    })
                                                    this.props.onChange({
                                                        placeId: children.ptt,
                                                        City: children.naziv,
                                                        Street: this.state.streetSearch ? this.state.streetSearch : this.props.value && this.props.value.Street ? this.props.value.Street : ''
                                                    })
                                                }}>{children.naziv}  {children.ptt}</DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                                :
                                null
                            }
                        </Dropdown>
                    </div>

                </Col>


            </Row>
        );
    }
}

export default Select;